import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Card from "~components/atoms/card/card";
import Heading from "~components/atoms/heading/heading";
import Image from "~components/atoms/image/image";
import RichText from "~components/molecules/rich_text/rich_text";
import { MEDIA, RICH_TEXT } from "../../../../../prop_types";
import {
  carouselItemWrapperStyle,
  imageWrapperStyle,
  textWrapperStyle,
} from "./carousel_item_new.module.scss";

function CarouselItemNew({
  image: imageArray,
  isActive,
  title,
  text,
  ...rest
}) {
  const [image] = imageArray;
  return (
    <SbEditable content={rest}>
      <Card stretch>
        <div className={carouselItemWrapperStyle}>
          <div className={imageWrapperStyle}>
            <Image {...image} />
          </div>
          <div className={textWrapperStyle}>
            <Heading visualLevel={3} seoLevel={3}>
              {title}
            </Heading>
            <RichText text={text} />
          </div>
        </div>
      </Card>
    </SbEditable>
  );
}

CarouselItemNew.propTypes = {
  image: PropTypes.arrayOf(MEDIA).isRequired,
  title: PropTypes.string.isRequired,
  text: RICH_TEXT.isRequired,
  isActive: PropTypes.bool,
};

CarouselItemNew.defaultProps = {
  isActive: false,
};

export default CarouselItemNew;
