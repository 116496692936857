import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Typewriter from "typewriter-effect";
import { animatedTextWrapperStyle } from "./animated_typewriter_text.module.scss";
import getTypewriterStrings from "./helper_functions/get_typewriter_strings/get_typewriter_strings";

function AnimatedTypewriterText({ delay, animatedTextItems }) {
  const stringArray = getTypewriterStrings({ animatedTextItems });

  const typewriterProps = useMemo(() => {
    return {
      strings: stringArray,
      autoStart: true,
      loop: true,
      delay,
    };
  }, [delay, stringArray]);

  if (animatedTextItems.length > 0) {
    return (
      <div className={animatedTextWrapperStyle}>
        <Typewriter options={typewriterProps} />
      </div>
    );
  }
}

AnimatedTypewriterText.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animatedTextItems: PropTypes.arrayOf(PropTypes.shape),
};

AnimatedTypewriterText.defaultProps = {
  delay: 100,
  animatedTextItems: [],
};

export default AnimatedTypewriterText;
