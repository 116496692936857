import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import BlogArticleListRecent from "~components/molecules/blog_article_list/blog_article_list_recent";
import { BLOK_ARRAY } from "../../../prop_types";

const HEADING_MARGIN = { marginBottom: "lg" };

function SectionRecentArticles({
  heading,
  id,
  sectionSettings: sectionSettingsArray,
  subscriptHeading,
  ...rest
}) {
  const [sectionSettings] = sectionSettingsArray || [];
  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain>
        {subscriptHeading && (
          <Heading
            {...rest}
            balanceText
            color="gray"
            isCentered
            isUppercase
            seoLevel={3}
            visualLevel={7}
          >
            {subscriptHeading}
          </Heading>
        )}
        {heading && (
          <Heading
            {...rest}
            balanceText
            isCentered
            margin={HEADING_MARGIN}
            seoLevel={3}
            visualLevel={2}
          >
            {heading}
          </Heading>
        )}
        <BlogArticleListRecent />
      </Contain>
    </Section>
  );
}

SectionRecentArticles.propTypes = {
  heading: PropTypes.string,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  subscriptHeading: PropTypes.string,
};

SectionRecentArticles.defaultProps = {
  heading: null,
  id: null,
  sectionSettings: null,
  subscriptHeading: null,
};

export default SectionRecentArticles;
