import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import Contain from "~components/atoms/contain/contain";
import { BLOK_ARRAY } from "../../../prop_types";
import { CAROUSEL_COMMON_PROPS } from "./carousel_common_props";
import * as classes from "./carousel_new.module.scss";
import CarouselControlsWrapper from "./components/carousel_controls_wrapper/carousel_controls_wrapper";
import CarouselItemNew from "./components/carousel_item_new/carousel_item_new";
import "./slick.scss";
import "./slick_dots.scss";

export default function CarouselNew({ children }) {
  const slider = useRef();
  const [slideIndex, setSlideIndex] = useState(0);

  const handleGoToSlide = useCallback((index) => {
    slider.current.slickGoTo(index);
    setSlideIndex(index);
  }, []);

  const carouselProps = useMemo(() => {
    return {
      ...CAROUSEL_COMMON_PROPS,
      afterChange: (index) => {
        setSlideIndex(index);
      },
    };
  }, []);

  return (
    <div className={classes.carouselWrapperStyle}>
      <CarouselControlsWrapper
        items={children}
        callbackToSetActiveIndex={handleGoToSlide}
        activeItemIndex={slideIndex}
      />
      <Contain>
        <Slider ref={slider} {...carouselProps}>
          {children?.length > 0 &&
            children.map((child, index) => {
              return (
                <CarouselItemNew
                  key={child.title}
                  isActive={slideIndex === index}
                  {...child}
                />
              );
            })}
        </Slider>
      </Contain>
    </div>
  );
}

CarouselNew.defaultProps = {
  children: null,
  eventId: null,
};

CarouselNew.propTypes = {
  children: BLOK_ARRAY,
  eventId: PropTypes.string,
};
