import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import SectionCareerBenefits from "~components/sections/section_career_benefits/section_career_benefits";
import SectionCareerCtaSearch from "~components/sections/section_career_cta_search/section_career_cta_search";
import SectionCareerHeroWrapper from "~components/sections/section_career_hero_wrapper/section_career_hero_wrapper";
import SectionCareerInterviewing from "~components/sections/section_career_interviewing/section_career_interviewing";
import SectionCareerMission from "~components/sections/section_career_mission/section_career_mission";
import SectionCtaBanner from "~components/sections/section_cta_banner/section_cta_banner";
import SectionFeatureGrid from "~components/sections/section_feature_grid/section_feature_grid";
import SectionOurValues from "~components/sections/section_our_values/section_our_values";
import SectionRecentArticles from "~components/sections/section_recent_articles/section_recent_articles";
import SectionRichTextWithContain from "~components/sections/section_rich_text_with_contain/section_rich_text_with_contain";
import renderDynamicComponent from "~components/utils/render_dynamic_component/render_dynamic_component";
import usePageData from "~hooks/use_page_data/use_page_data";
import { STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";

const TEMPLATE_COMPONENTS = {
  sectionCareerBenefits: SectionCareerBenefits,
  sectionCareerCtaSearch: SectionCareerCtaSearch,
  sectionCareerHeroWrapper: SectionCareerHeroWrapper,
  sectionCareerInterviewing: SectionCareerInterviewing,
  sectionCareerMission: SectionCareerMission,
  sectionCtaBanner: SectionCtaBanner,
  sectionFeatureGrid: SectionFeatureGrid,
  sectionOurValues: SectionOurValues,
  sectionRecentArticles: SectionRecentArticles,
  sectionRichTextWithContain: SectionRichTextWithContain,
};

export default function TemplateCareersPage(props) {
  const { story, pageContext } = usePageData(props);

  const { children } = story;
  const { subNavData, lang } = pageContext;

  return (
    <TemplateGlobalEntry
      headerColor="blue"
      lang={lang}
      subNavData={subNavData}
      {...props}
    >
      {children?.length > 0 &&
        children.map((childComponent) => {
          return renderDynamicComponent({
            parentComponentName: "TemplateCareersPage",
            componentName: childComponent.component,
            componentsMap: TEMPLATE_COMPONENTS,
            ...childComponent,
          });
        })}
    </TemplateGlobalEntry>
  );
}

TemplateCareersPage.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

TemplateCareersPage.defaultProps = {
  pageContext: {
    subNavData: [],
  },
};
// TODO: get page specific metadata here
export const query = graphql`
  query TemplateCareersPageQuery($id: String, $langRegex: String) {
    ...story
    ...global
  }
`;
