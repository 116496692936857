import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import GridItemNew from "~components/atoms/grid_new/grid_item_new/grid_item_new";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import RichText from "~components/molecules/rich_text/rich_text";
import AccordionList from "~components/organisms/accordion_list/accordion_list";
import { BLOK_ARRAY } from "../../../prop_types";
import { contentWrapperStyle } from "./section_career_interviewing.module.scss";

const GRID_SPLIT = { XXL: "2", M: "1" };

function SectionCareerInterviewing({
  accordionList,
  bodyRichText: bodyRichTextArray,
  ctaResponsive: ctaResponsiveArray,
  heading,
  id,
  sectionSettings: sectionSettingsArray,
  subscriptHeading,
  ...rest
}) {
  const [bodyRichText] = bodyRichTextArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  if (accordionList?.length > 0) {
    return (
      <Section id={id} sectionSettings={sectionSettings} {...rest}>
        <Contain>
          <GridNew split={GRID_SPLIT}>
            <GridItemNew>
              <div className={contentWrapperStyle}>
                {subscriptHeading && (
                  <Heading
                    {...rest}
                    balanceText
                    color="gray"
                    isUppercase
                    seoLevel={3}
                    visualLevel={7}
                  >
                    {subscriptHeading}
                  </Heading>
                )}
                {heading && (
                  <Heading {...rest} balanceText seoLevel={3} visualLevel={3}>
                    {heading}
                  </Heading>
                )}
                {bodyRichText && <RichText {...bodyRichText} />}
              </div>
            </GridItemNew>
            <GridItemNew>
              <AccordionList>{accordionList}</AccordionList>
            </GridItemNew>
          </GridNew>
        </Contain>
      </Section>
    );
  }
  return null;
}

SectionCareerInterviewing.propTypes = {
  accordionList: BLOK_ARRAY,
  bodyRichText: BLOK_ARRAY,
  ctaResponsive: BLOK_ARRAY,
  heading: PropTypes.string,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  subscriptHeading: PropTypes.string,
};

SectionCareerInterviewing.defaultProps = {
  accordionList: null,
  bodyRichText: null,
  ctaResponsive: null,
  heading: null,
  id: null,
  sectionSettings: null,
  subscriptHeading: null,
};

export default SectionCareerInterviewing;
