import React from "react";
import PropTypes from "prop-types";
import Section from "~components/atoms/section/section";
import CarouselNew from "~components/organisms/carousel/carousel_new";
import { BLOK_ARRAY } from "../../../prop_types";

function SectionCareerBenefits({
  carouselItems,
  id,
  sectionSettings: sectionSettingsArray,
  ...rest
}) {
  const [sectionSettings] = sectionSettingsArray || [];

  if (carouselItems?.length > 0) {
    return (
      <Section id={id} sectionSettings={sectionSettings} {...rest}>
        <CarouselNew>{carouselItems}</CarouselNew>
      </Section>
    );
  }

  return null;
}

SectionCareerBenefits.propTypes = {
  carouselItems: BLOK_ARRAY,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
};

SectionCareerBenefits.defaultProps = {
  carouselItems: null,
  id: null,
  sectionSettings: null,
};

export default SectionCareerBenefits;
