import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import GridItemNew from "~components/atoms/grid_new/grid_item_new/grid_item_new";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import Wrapper from "~components/atoms/wrapper/wrapper";
import AnimatedTitleSection from "~components/molecules/animated_title_section/animated_title_section";
import SearchJobsCTACard from "~components/organisms/search/search_jobs/search_jobs_cta_card/search_jobs_cta_card";
import { BLOK_ARRAY, RESPONSIVE_VALUE_NEW } from "../../../prop_types";
import { heroWrapperStyle } from "./section_career_hero_wrapper.module.scss";

const HEADING_GRID_ITEM_SPAN = { XXL: 2 };
const CTA_CARD_GRID_ITEM_SPAN = { XXL: 1 };
const HEADING_MARGIN = { marginTop: "md" };

function SectionCareerHeroWrapper({
  animatedTitleSection: animatedTitleSectionArray,
  gridSplit,
  id,
  jobSearchCtaCard: jobSearchCtaCardArray,
  sectionSettings: sectionSettingsArray,
  subscriptHeading,
  ...rest
}) {
  const [animatedTitleSection] = animatedTitleSectionArray || [];
  const [sectionSettings] = sectionSettingsArray || [];
  const [jobSearchCtaCard] = jobSearchCtaCardArray || [];

  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <div className={heroWrapperStyle}>
        <Contain>
          <GridNew split={gridSplit}>
            <GridItemNew gridSpan={HEADING_GRID_ITEM_SPAN}>
              <Wrapper>
                {subscriptHeading && (
                  <Heading
                    {...rest}
                    balanceText
                    color="gray"
                    isUppercase
                    margin={HEADING_MARGIN}
                    seoLevel={1}
                    visualLevel={7}
                  >
                    {subscriptHeading}
                  </Heading>
                )}
                {animatedTitleSection && (
                  <AnimatedTitleSection {...animatedTitleSection} />
                )}
              </Wrapper>
            </GridItemNew>
            <GridItemNew gridSpan={CTA_CARD_GRID_ITEM_SPAN}>
              <SearchJobsCTACard {...jobSearchCtaCard} />
            </GridItemNew>
          </GridNew>
        </Contain>
      </div>
    </Section>
  );
}

SectionCareerHeroWrapper.propTypes = {
  animatedTitleSection: BLOK_ARRAY,
  gridSplit: RESPONSIVE_VALUE_NEW,
  id: PropTypes.string,
  subscriptHeading: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  jobSearchCtaCard: BLOK_ARRAY,
};

SectionCareerHeroWrapper.defaultProps = {
  animatedTitleSection: null,
  gridSplit: null,
  id: null,
  sectionSettings: null,
  subscriptHeading: null,
  jobSearchCtaCard: null,
};

export default SectionCareerHeroWrapper;
