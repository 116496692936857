// Notes: This component uses useStaticQuery to query for blog posts for culture only
// This is a nice way of dropping programmatic content into a page via the drag-and-drop
// editor. Ideally we could pass a category as a prop, but that sort of approach, or interpolating a string
// into a query are apparently not recommended approaches. We could look into this deeper though there may be a workaround.
// — useStaticQuery does not accept variables (hence the name "static"), but can be used in any component, including pages
// — Because of how queries currently work in Gatsby, we support only a single instance of useStaticQuery in a file
// https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
// ToDo: Figure out how to make a version of this component
// that can dynamically be passed a category and return posts from that
// Maybe we should be building queries, but in a more intelligent way than we currently do
// e.g. https://www.npmjs.com/package/gql-query-builder
import React, { useMemo, useRef } from "react";
import Slider from "react-slick";
import Contain from "~components/atoms/contain/contain";
import { CAROUSEL_COMMON_PROPS } from "~components/organisms/carousel/carousel_common_props";
import { AssetsContextProvider } from "../../../assets_context";
import RscHbCardArticleFeatured from "../rsc_hb_card/rsc_hb_card_article_featured/rsc_hb_card_article_featured";
import { wrapperStyle } from "./blog_article_list_featured.module.scss";
import useQueriedArticles from "./hooks/use_queried_articles";
import useQueryArticlesCareersHubFeatured from "./hooks/use_query_articles_careers_hub_featured";

export default function BlogArticleListFeatured() {
  const queryResult = useQueryArticlesCareersHubFeatured();

  const [articleAssets, articles] = useQueriedArticles(
    queryResult?.posts?.nodes
  );

  const slider = useRef();

  const carouselProps = useMemo(() => {
    return {
      ...CAROUSEL_COMMON_PROPS,
    };
  }, []);

  if (articles?.length > 0 && articleAssets?.length > 0) {
    return (
      <AssetsContextProvider storyAssets={articleAssets}>
        <div className={wrapperStyle}>
          <Contain>
            <Slider ref={slider} {...carouselProps}>
              {articles.map((article) => {
                return (
                  <RscHbCardArticleFeatured key={article.id} {...article} />
                );
              })}
            </Slider>
          </Contain>
        </div>
      </AssetsContextProvider>
    );
  }

  return null;
}

BlogArticleListFeatured.propTypes = {};

BlogArticleListFeatured.defaultProps = {};

// Todo(BSE): Test this blog article list component
