import React, { useState } from "react";
import algoliasearch from "algoliasearch";
import PropTypes from "prop-types";
import { InstantSearch } from "react-instantsearch-dom";
import Card from "~components/atoms/card/card";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Button from "~components/molecules/button/button/button";
import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";
import SearchJobsFields from "../search_jobs_fields/search_jobs_fields";
import {
  cardWrapperStyle,
  ctaWrapperStyle,
} from "./search_jobs_cta_card.module.scss";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

export default function SearchJobsCTACard({
  heading,
  buttonText,
  jobsPathString,
}) {
  const [searchState, setSearchState] = useState({});

  const path = createUrlPathFromArray([jobsPathString]);
  const linkWithSearchState = createInternalLink(path, searchState);

  return (
    <Contain>
      <div className={cardWrapperStyle}>
        <Card isFullWidth overflowHidden={false}>
          <div className={ctaWrapperStyle}>
            <Heading isCentered visualLevel={5} seoLevel={4}>
              {heading}
            </Heading>
            <InstantSearch
              searchClient={searchClient}
              indexName="careers hub data"
              searchState={searchState}
              onSearchStateChange={setSearchState}
            >
              <SearchJobsFields searchState={searchState} />
            </InstantSearch>
            <Button
              isFullWidth
              text={buttonText}
              link={linkWithSearchState}
              size="large"
            />
          </div>
        </Card>
      </div>
    </Contain>
  );
}

SearchJobsCTACard.propTypes = {
  buttonText: PropTypes.string,
  heading: PropTypes.string,
  jobsPathString: PropTypes.string,
};

SearchJobsCTACard.defaultProps = {
  buttonText: null,
  heading: null,
  jobsPathString: null,
};
