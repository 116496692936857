// eslint-disable-next-line import/prefer-default-export
export const CAROUSEL_COMMON_PROPS = {
  arrows: false,
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: false,
  draggable: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  waitForAnimate: false,
};
