import React from "react";

import PropTypes from "prop-types";

import { BLOK_ARRAY } from "../../../../../prop_types";
import CarouselControlText from "../carousel_control/carousel_control_text";
import { carouselControlsWrapperStyle } from "./carousel_controls_wrapper.module.scss";

function CarouselControlsWrapper({
  items,
  activeItemIndex,
  callbackToSetActiveIndex,
}) {
  if (items?.length > 0) {
    return (
      <ul className={carouselControlsWrapperStyle}>
        {items.map((child, index) => {
          return (
            <CarouselControlText
              title={child.buttonTitle}
              callbackToSetActiveIndex={callbackToSetActiveIndex}
              isActive={activeItemIndex === index}
              key={child._uid}
              index={index}
            />
          );
        })}
      </ul>
    );
  }
}

CarouselControlsWrapper.propTypes = {
  items: BLOK_ARRAY,
  activeItemIndex: PropTypes.number,
  callbackToSetActiveIndex: PropTypes.func,
};

CarouselControlsWrapper.defaultProps = {
  items: [],
  activeItemIndex: 0,
  callbackToSetActiveIndex: () => {},
};

export default CarouselControlsWrapper;
