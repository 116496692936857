import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Section from "~components/atoms/section/section";
import RichText from "~components/molecules/rich_text/rich_text";
import { BLOK_ARRAY } from "../../../prop_types";

function SectionRichTextWithContain({
  contentRichText: contentRichTextArray,
  id,
  sectionSettings: sectionSettingsArray,
  ...rest
}) {
  const [contentRichText] = contentRichTextArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  if (contentRichText) {
    return (
      <Section id={id} sectionSettings={sectionSettings} {...rest}>
        <Contain>
          <RichText {...contentRichText} />
        </Contain>
      </Section>
    );
  }

  return null;
}

SectionRichTextWithContain.propTypes = {
  contentRichText: BLOK_ARRAY,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
};

SectionRichTextWithContain.defaultProps = {
  contentRichText: null,
  id: null,
  sectionSettings: null,
};

export default SectionRichTextWithContain;
