import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import BlogArticleListFeatured from "~components/molecules/blog_article_list/blog_article_list_featured";
import { BLOK_ARRAY } from "../../../prop_types";

const SUBHEADING_MARGIN = { marginTop: "md" };

function SectionCareerMission({
  heading,
  subheading,
  subscriptHeading,
  sectionSettings: sectionSettingsArray,
  id,
  ...rest
}) {
  const [sectionSettings] = sectionSettingsArray || [];
  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain small>
        {subscriptHeading && (
          <Heading
            {...rest}
            balanceText
            color="gray"
            isCentered
            isUppercase
            seoLevel={3}
            visualLevel={7}
          >
            {subscriptHeading}
          </Heading>
        )}
        {heading && (
          <Heading
            {...rest}
            balanceText
            isCentered
            seoLevel={4}
            visualLevel={3}
          >
            {heading}
          </Heading>
        )}
        {subheading && (
          <Heading
            {...rest}
            balanceText
            isCentered
            margin={SUBHEADING_MARGIN}
            seoLevel={5}
            visualLevel={5}
          >
            {subheading}
          </Heading>
        )}
      </Contain>
      <BlogArticleListFeatured />
    </Section>
  );
}

SectionCareerMission.propTypes = {
  heading: PropTypes.string,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  subheading: PropTypes.string,
  subscriptHeading: PropTypes.string,
};

SectionCareerMission.defaultProps = {
  heading: null,
  id: null,
  sectionSettings: null,
  subheading: null,
  subscriptHeading: null,
};

export default SectionCareerMission;
