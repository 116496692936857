import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import RichText from "~components/molecules/rich_text/rich_text";
import { BLOK_ARRAY } from "../../../prop_types";

function SectionCtaBanner({
  contentRichText: contentRichTextArray,
  ctaResponsive: ctaResponsiveArray,
  heading,
  id,
  sectionSettings: sectionSettingsArray,
  subscriptHeading,
  ...rest
}) {
  const [contentRichText] = contentRichTextArray || [];
  const [ctaResponsive] = ctaResponsiveArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain>
        {subscriptHeading && (
          <Heading
            {...rest}
            balanceText
            color="white"
            isCentered
            isUppercase
            opacity={0.5}
            seoLevel={3}
            visualLevel={7}
          >
            {subscriptHeading}
          </Heading>
        )}
        {heading && (
          <Heading
            {...rest}
            balanceText
            color="white"
            isCentered
            seoLevel={3}
            visualLevel={2}
          >
            {heading}
          </Heading>
        )}
        {contentRichText && (
          <RichText {...contentRichText} balanceText isCentered />
        )}
        {ctaResponsive && <CtaResponsive {...ctaResponsive} isCentered />}
      </Contain>
    </Section>
  );
}

SectionCtaBanner.propTypes = {
  contentRichText: BLOK_ARRAY,
  ctaResponsive: BLOK_ARRAY,
  heading: PropTypes.string,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  subscriptHeading: PropTypes.string,
};

SectionCtaBanner.defaultProps = {
  contentRichText: null,
  ctaResponsive: null,
  heading: null,
  id: null,
  sectionSettings: null,
  subscriptHeading: null,
};

export default SectionCtaBanner;
