import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import useMatchMedia from "~hooks/use_match_media/use_match_media";
import { BLOK_ARRAY } from "../../../prop_types";
import AnimatedTypewriterText from "./components/animated_typewriter_text/animated_typewriter_text";

function AnimatedTitleSection({
  leadingText,
  trailingText,
  animatedText: animatedTextArray,
}) {
  // Destructure nested array of 1 component in Storyblok response
  const [animatedText] = animatedTextArray;

  const isMobileWindow = useMatchMedia({ max: "M" });
  const headingSize = isMobileWindow ? 3 : 2;

  if (
    leadingText.length > 0 &&
    trailingText.length > 0 &&
    animatedTextArray.length > 0
  ) {
    return (
      <div>
        <Heading isXL visualLevel={1} seoLevel={headingSize}>
          <div>{leadingText}</div>
          {animatedTextArray && <AnimatedTypewriterText {...animatedText} />}
          <div>{trailingText}</div>
        </Heading>
      </div>
    );
  }
  return null;
}

AnimatedTitleSection.propTypes = {
  leadingText: PropTypes.string,
  trailingText: PropTypes.string,
  animatedText: BLOK_ARRAY,
};

AnimatedTitleSection.defaultProps = {
  leadingText: null,
  trailingText: null,
  animatedText: null,
};

export default AnimatedTitleSection;
