import React from "react";
import PropTypes from "prop-types";
import Column from "~components/atoms/column/column";
import Heading from "~components/atoms/heading/heading";
import Row from "~components/atoms/row/row";
import Section from "~components/atoms/section/section";
import SearchJobsCTACard from "~components/organisms/search/search_jobs/search_jobs_cta_card/search_jobs_cta_card";
import { BLOK_ARRAY } from "../../../prop_types";

const COLUMN_SPAN = { XXL: 5, M: 8, XS: 12 };

function SectionCareerCtaSearch({
  heading,
  id,
  sectionSettings: sectionSettingsArray,
  jobSearchCtaCard: jobSearchCtaCardArray,
  ...rest
}) {
  const [sectionSettings] = sectionSettingsArray || [];
  const [jobSearchCtaCard] = jobSearchCtaCardArray || [];
  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Row isContentCentered>
        <Column span={COLUMN_SPAN}>
          {heading && (
            <Heading
              {...rest}
              balanceText
              color="white"
              isCentered
              seoLevel={3}
              visualLevel={3}
            >
              {heading}
            </Heading>
          )}
          <SearchJobsCTACard {...jobSearchCtaCard} />
        </Column>
      </Row>
    </Section>
  );
}

SectionCareerCtaSearch.propTypes = {
  heading: PropTypes.string,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  jobSearchCtaCard: BLOK_ARRAY,
};

SectionCareerCtaSearch.defaultProps = {
  heading: null,
  id: null,
  sectionSettings: null,
  jobSearchCtaCard: null,
};

export default SectionCareerCtaSearch;
