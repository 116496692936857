import React from "react";
import PropTypes from "prop-types";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY } from "../../../prop_types";
import * as classes from "./row.module.scss";

export default function Row({ children, settings, isContentCentered }) {
  const [settingsClassNames] = useSettingsClassNames(settings);
  const classNames = [classes.row, ...settingsClassNames];

  if (isContentCentered) {
    classNames.push(classes.isContentCentered);
  }

  return <div className={classNames.join(" ")}>{children}</div>;
}

Row.defaultProps = {
  children: null,
  settings: null,
  isContentCentered: false,
};

Row.propTypes = {
  children: PropTypes.oneOfType([BLOK_ARRAY, PropTypes.node]),
  settings: BLOK_ARRAY,
  isContentCentered: PropTypes.bool,
};
