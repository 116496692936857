import React, { useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ButtonTertiary from "~components/molecules/button/button_tertiary/button_tertiary";
import {
  buttonWrapperStyle,
  isActiveStyle,
} from "./carousel_control_text.module.scss";

const BUTTON_MARGIN = { marginBottom: "none", marginTop: "none" };

function CarouselControlText({
  callbackToSetActiveIndex,
  isActive,
  title,
  index,
}) {
  const setActiveIndex = useCallback(() => {
    callbackToSetActiveIndex(index);
  }, [callbackToSetActiveIndex, index]);

  const carouselControlClassnames = classNames(buttonWrapperStyle, {
    [isActiveStyle]: isActive,
  });

  return (
    <div className={carouselControlClassnames}>
      <ButtonTertiary
        margin={BUTTON_MARGIN}
        title={title}
        onClick={setActiveIndex}
        color="cloudBurst"
        isActive={isActive}
      />
    </div>
  );
}

CarouselControlText.propTypes = {
  callbackToSetActiveIndex: PropTypes.func,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  index: PropTypes.number,
};

CarouselControlText.defaultProps = {
  callbackToSetActiveIndex: () => {},
  isActive: false,
  title: "",
  index: null,
};

export default CarouselControlText;
