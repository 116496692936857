// Notes: This component uses useStaticQuery to query for blog posts for culture only
// This is a nice way of dropping programmatic content into a page via the drag-and-drop
// editor. Ideally we could pass a category as a prop, but that sort of approach, or interpolating a string
// into a query are apparently not recommended approaches. We could look into this deeper though there may be a workaround.
// — useStaticQuery does not accept variables (hence the name "static"), but can be used in any component, including pages
// — Because of how queries currently work in Gatsby, we support only a single instance of useStaticQuery in a file
// https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
// ToDo: Figure out how to make a version of this component
// that can dynamically be passed a category and return posts from that
// Maybe we should be building queries, but in a more intelligent way than we currently do
// e.g. https://www.npmjs.com/package/gql-query-builder
import React from "react";
import GridNew from "~components/atoms/grid_new/grid_new";
import { AssetsContextProvider } from "../../../assets_context";
import RscHbCardArticle from "../rsc_hb_card/rsc_hb_card_article/rsc_hb_card_article";
import useQueriedArticles from "./hooks/use_queried_articles";
import useQueryArticlesRecent from "./hooks/use_query_articles_recent";

const GRID_SPLIT_ARTICLES = { XXL: "3", S: "1" };

export default function BlogArticleListRecent() {
  const queryResult = useQueryArticlesRecent();

  const [articleAssets, articles] = useQueriedArticles(
    queryResult?.posts?.nodes
  );

  if (articles?.length > 0 && articleAssets.length > 0) {
    return (
      <AssetsContextProvider storyAssets={articleAssets}>
        <GridNew split={GRID_SPLIT_ARTICLES}>
          {articles.map((article) => {
            return <RscHbCardArticle key={article.id} {...article} stretch />;
          })}
        </GridNew>
      </AssetsContextProvider>
    );
  }

  return null;
}

BlogArticleListRecent.propTypes = {};

BlogArticleListRecent.defaultProps = {};
